import { securedWrap } from '@mop/shared/utils/securedWrap';
import { localStorageGet, localStorageRemove, localStorageSet } from '@mop/shared/utils/localStorage';
import { generateRandomChars, loadScript, loadCss } from '@mop/shared/utils/util';
import type { ProductModel } from '@/types/product';
import type { GtmCustomTrackingParams } from '@/types/gtm';

type WidgetParameters = {
  brandcode: string;
  lang: string;
  productcode: string;
  visitorid: string;
};

const SAIZ_BRAND_CODE = 'MOP';
const isSaizScriptAddedRef = ref<boolean>(false);
const isSaizWidgetAvailableRef = ref<boolean>(false);
const suggestedSizeRef = ref<string | null>(null);
const productIdRef = ref<string | undefined>();
const hasSuggestedSizeBeenFoundRef = ref<boolean>(false);
let isReportSizeAdviceStarted = false;
let reportSizeAdviceCustomData: GtmCustomTrackingParams | undefined;

export default function useMopSaizClient() {
  const { $mopI18n, $mopConfig } = useNuxtApp();
  const { hasPersonalizationCookiesBeenAccepted } = useMopPrivacy();
  const { reportEngagement } = useMopTrackingClient();

  function reportSizeAdviceStart(product: ProductModel, referer: string) {
    isReportSizeAdviceStarted = true;
    reportSizeAdviceCustomData = {
      category: product.getPrimaryCategoryId(),
      label: product.getMopId(),
      parameter3: referer,
      event: 'startSaiz',
    };
    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: reportSizeAdviceCustomData,
        },
      },
    });
  }

  function reportSizeAdviceEnd() {
    if (isReportSizeAdviceStarted && reportSizeAdviceCustomData) {
      reportSizeAdviceCustomData.event = 'finishSaiz';
      reportEngagement({
        gtm: {
          type: 'Click',
          data: {
            custom: reportSizeAdviceCustomData,
          },
        },
      });
      resetSizeAdviceTracking();
    }
  }

  function resetSizeAdviceTracking() {
    isReportSizeAdviceStarted = false;
    reportSizeAdviceCustomData = undefined;
  }

  function openSaizWidget(trackingProduct: ProductModel, trackingReferer: string) {
    document.getElementById('saiz-floating-button')?.click();
    reportSizeAdviceStart(trackingProduct, trackingReferer);
  }

  function getWidgetParameters(): WidgetParameters {
    const sessionId = localStorageGet(constants.LOCAL_STORAGE.SAIZ_SESSION_ID) ?? '';
    return {
      lang: $mopI18n.lang.toUpperCase(),
      productcode: productIdRef.value ?? '',
      visitorid: sessionId,
      brandcode: SAIZ_BRAND_CODE,
    };
  }

  function emitSaizInitEvent() {
    const event = new CustomEvent('saiz-widget-initializer', {
      detail: getWidgetParameters(),
    });
    window.dispatchEvent(event);
  }

  function setSaizWidgetAvailable(isAvailable: boolean) {
    isSaizWidgetAvailableRef.value = isAvailable;
  }

  function handleSuggestedSizeFound() {
    if (!suggestedSizeRef.value) {
      return;
    }
    hasSuggestedSizeBeenFoundRef.value = true;

    reportSizeAdviceEnd();
  }

  function setSuggestedSize(size: string | null) {
    suggestedSizeRef.value = size;
    handleSuggestedSizeFound();
  }

  function setSaizProductId(productId: string | undefined) {
    if (productIdRef.value === productId) {
      return;
    }
    productIdRef.value = productId;
    resetSizeAdviceTracking();
    if (productId && isSaizScriptAddedRef.value) {
      emitSaizInitEvent();
    }
  }

  function resetSaizState() {
    setSaizWidgetAvailable(false);
    setSuggestedSize(null);
    setSaizProductId(undefined);
  }

  function initSaiz() {
    const config = useRuntimeConfig();

    if (!$mopConfig.isSizeAdviceEnabled() || isSaizScriptAddedRef.value) {
      return;
    }

    if (!hasPersonalizationCookiesBeenAccepted()) {
      localStorageRemove(constants.LOCAL_STORAGE.SAIZ_SESSION_ID);
      return;
    }
    let sessionId = localStorageGet(constants.LOCAL_STORAGE.SAIZ_SESSION_ID);
    if (!sessionId) {
      sessionId = generateRandomChars(32);
      localStorageSet(constants.LOCAL_STORAGE.SAIZ_SESSION_ID, sessionId);
    }

    loadScript({
      source: config.public.SAIZ_JS_URL,
      callback: () => {
        isSaizScriptAddedRef.value = true;
      },
    });
    loadCss({ source: config.public.SAIZ_CSS_URL });
  }

  return securedWrap({
    isSaizWidgetAvailableRef,
    setSaizWidgetAvailable,
    suggestedSizeRef,
    setSuggestedSize,
    productIdRef,
    setSaizProductId,
    resetSaizState,
    getWidgetParameters,
    openSaizWidget,
    hasSuggestedSizeBeenFoundRef,
    initSaiz,
    isSaizScriptAddedRef,
  });
}
